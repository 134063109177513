<template>
    <div class="evaluation-main">
          <div class="header-search">
            <span class="search-item">
              <span class="search-name">评价内容:</span>
              <comInput class="input-width" v-model="content.value" :data='content'></comInput>
            </span>
            <span class="operate-btn">
              <fontButton @click="search">查询</fontButton>
              <fontButton @click="reset">重置</fontButton>
           </span>
          </div>
          <div class="table-main">
            <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
                <template slot="operate" slot-scope="data">
                </template>
            </ContentTable>
          </div>
    </div>
</template>
<script>
import {ajax_get_evaluation} from "../../../api/index"
import {tableData_evaluation} from "../common/data"
import basePage from "../common/basePage.vue"
export default {
    name:'evaluationMain',
    mixins: [basePage],
    data(){
        return {
            content:{
                placeholder: "请输入评价内容",
                value: ""
            },
        }
    },
    created(){
      this.pageInit(); 
    },
    methods:{
        pageInit(){
          this.ajaxListMethods = ajax_get_evaluation;
          this.tableData = this.$deepClone(tableData_evaluation);
          this.ajax_list_get(true)
        },
        search(){
            this.ajaxGetData.content = this.content.value
            this.ajax_list_get(true)
        },
        handerTableData(list){
          let resList = [];
          list.forEach((element,index)=>{
            element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
            resList.push(element)
          })
          return resList;
        },
        reset(){
            this.content.value = ""
        },
    }
}
</script>
<style lang="less">
.evaluation-main{
    width: 100%;
    height: 100%;
    .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    .search-item{
      margin-right: 15px;
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
    }
    .operate-btn{

    }
  }
}
</style>